.layoutWrapper {
  padding-bottom: 5rem;

  .navbar {
    margin: 0.5rem auto;
    max-width: 210mm;
    display: flex;
    justify-content: space-between;
  }
}

.printBtn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.mainBanner {
  background-color: rgb(46, 46, 53);
  color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 0;
  

  h1{
    font-size: 48px;
  }
}
$spacing-block: 20pt;
$spacing-inline: 20pt;

@page {
  font-size: 0px;
  margin: $spacing-block $spacing-inline;
  wide {
    size: a4 portrait;
  }

  @top-center {
    content: none;
  }
}

.printViewBlock {
  padding: $spacing-block $spacing-inline;
  max-width: 210mm;
  margin-inline: auto;
  font-size: 12pt;
}

@media print {
  :global {
    * {
      visibility: hidden;
    }

    body {
      margin: 0;
      /* Reset default browser margin */
    }
  }

  .printBlock {
    position: absolute;
    left: 0;
    top: 0;
  }

  .printBlock,
  .printBlock * {
    visibility: visible;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}